import React from 'react';

export interface AppConfig {
  region: string;
  appName: string;
  defaultLanguage: string;
  paymentMethod: string;
  officialWebsiteUrl: string;
}

const AppConfigContext = React.createContext<AppConfig | undefined>(undefined);

export default AppConfigContext;
