import '@authing/guard-react18/dist/esm/guard.min.css';

import './index.css';
import './localization/i18n';

import { StyleProvider } from '@ant-design/cssinjs';
import { GuardOptions, GuardProvider } from '@authing/guard-react18';
import { App, ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import zhCN from 'antd/locale/zh_CN';
import { SocialConnectionProvider } from 'authing-js-sdk';
import i18n from 'i18next';
import { ComponentProps, JSXElementConstructor, Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  redirect
} from 'react-router-dom';
import { SWRConfig } from 'swr';

import Loading from './components/Loading';
import AppConfigProvider from './contexts/AppConfigProvider';

const LayoutBasic = lazy(() => import('./components/LayoutBasic'));
const LayoutBasicUser = lazy(() => import('./components/LayoutBasicUser'));
const LayoutBasicUserWalkthrough = lazy(
  () => import('./components/LayoutBasicUserWalkthrough')
);
const LayoutBasicLawyer = lazy(() => import('./components/LayoutBasicLawyer'));
const LayoutBasicAdmin = lazy(() => import('./components/LayoutBasicAdmin'));
const LayoutAuth = lazy(() => import('./components/LayoutAuth'));

const Home = lazy(() => import('./views/Home'));
const UserContract = lazy(() => import('./views/UserContract'));
const UserContractReview = lazy(() => import('./views/UserContractReview'));
const UserWalkthrough = lazy(() => import('./views/UserWalkthrough'));
const UserWalkthroughSpecifyReviewRequirements = lazy(
  () => import('./views/UserWalkthroughSpecifyReviewRequirements')
);
const UserWalkthroughCommunicateWithLawyer = lazy(
  () => import('./views/UserWalkthroughCommunicateWithLawyer')
);
const UserWalkthroughRequestNewVersion = lazy(
  () => import('./views/UserWalkthroughRequestNewVersion')
);
const LawyerContract = lazy(() => import('./views/LawyerContract'));
const LawyerContractReview = lazy(() => import('./views/LawyerContractReview'));
const AdminContract = lazy(() => import('./views/AdminContract'));
const AdminContractReview = lazy(
  () => import('./views/AdminContractReview/AdminContractReview')
);
const Profile = lazy(() => import('./views/Profile'));
const Auth = lazy(() => import('./views/Auth'));
const EmbedUpload = lazy(() => import('./views/EmbedUpload'));
const EmbedReview = lazy(() => import('./views/EmbedReview'));

const antdLocaleMap = new Map([
  ['zh-CN', zhCN],
  ['en-US', enUS]
]);

const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutBasic />,
    children: [
      {
        path: '/',
        element: <Home />
      },
      {
        path: '/user',
        element: <LayoutBasicUser />,
        children: [
          {
            path: '/user',
            loader: () => redirect('/user/contract')
          },
          {
            path: '/user/contract',
            element: <UserContract />
          },
          {
            path: '/user/contract/:contractId',
            element: <UserContractReview />
          }
        ]
      },
      {
        path: '/user/walkthrough',
        element: <LayoutBasicUserWalkthrough />,
        children: [
          {
            path: '/user/walkthrough',
            element: <UserWalkthrough />
          },
          {
            path: '/user/walkthrough/specify-review-requirements',
            element: <UserWalkthroughSpecifyReviewRequirements />
          },
          {
            path: '/user/walkthrough/communicate-with-lawyer',
            element: <UserWalkthroughCommunicateWithLawyer />
          },
          {
            path: '/user/walkthrough/request-new-version',
            element: <UserWalkthroughRequestNewVersion />
          }
        ]
      },
      {
        path: '/lawyer',
        element: <LayoutBasicLawyer />,
        children: [
          {
            path: '/lawyer',
            loader: () => redirect('/lawyer/contract')
          },
          {
            path: '/lawyer/contract',
            element: <LawyerContract />
          },
          {
            path: '/lawyer/contract/:contractId',
            element: <LawyerContractReview />
          }
        ]
      },
      {
        path: '/admin',
        element: <LayoutBasicAdmin />,
        children: [
          {
            path: '/admin',
            loader: () => redirect('/admin/contract')
          },
          {
            path: '/admin/contract',
            element: <AdminContract />
          },
          {
            path: '/admin/contract/:contractId',
            element: <AdminContractReview />
          }
        ]
      },
      {
        path: '/profile',
        element: <Profile />
      }
    ]
  },
  {
    path: '/auth',
    element: <LayoutAuth />,
    children: [
      {
        path: '/auth',
        element: <Auth />
      }
    ]
  },
  {
    path: '/upload',
    element: <EmbedUpload />
  },
  {
    path: '/review/:contractId',
    element: <EmbedReview />
  }
]);

let guardConfig: GuardOptions & ComponentProps<JSXElementConstructor<any>> = {};

if (window.location.hostname.includes('aligna')) {
  guardConfig.loginMethodList = [];
  guardConfig.socialConnections = [SocialConnectionProvider.GOOGLE];
} else {
  // default
  guardConfig.socialConnections = [];
}

createRoot(document.getElementById('root') as HTMLElement).render(
  <AppConfigProvider>
    <GuardProvider
      appId={process.env.REACT_APP_AUTHING_APP_ID}
      lang={i18n.language}
      config={guardConfig}
    >
      <StyleProvider hashPriority="high">
        <ConfigProvider
          locale={antdLocaleMap.get(i18n.language)}
          theme={{
            components: {
              Table: {
                headerSplitColor: 'transparent'
              },
              Tooltip: {
                colorBgSpotlight: '#fff',
                colorText: '#333',
                colorTextLightSolid: '#333'
              }
            }
          }}
        >
          <App>
            <SWRConfig
              value={{
                revalidateOnMount: true,
                revalidateOnFocus: false,
                shouldRetryOnError: false,
                dedupingInterval: 300
              }}
            >
              <Suspense fallback={<Loading className="!h-dvh !w-dvw" />}>
                <RouterProvider router={router} />
              </Suspense>
            </SWRConfig>
          </App>
        </ConfigProvider>
      </StyleProvider>
    </GuardProvider>
  </AppConfigProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
