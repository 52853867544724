import { Spin } from 'antd';

export default function Loading({ className }: { className?: string }) {
  return (
    <div
      className={`flex h-full w-full items-center justify-center ${className ?? ''}`}
    >
      <Spin />
    </div>
  );
}
