import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../assets/i18n/en.json';
import zh from '../assets/i18n/zh.json';

const resources = {
  en: {
    translation: en
  },
  zh: {
    translation: zh
  }
};

const urlLanguageMap = new Map<string, string>([
  ['aligna', 'en-US'],
  ['lanquan', 'zh-CN'],
  ['localhost', 'zh-CN']
]);

function getLanguageFromHostname(hostname: string): string | undefined {
  let language: string | undefined;

  urlLanguageMap.forEach((value, key) => {
    if (hostname.includes(key)) {
      language = value;
    }
  });

  if (!language) {
    language = urlLanguageMap.get('localhost');
  }

  return language;
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: getLanguageFromHostname(window.location.hostname),
    // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
