import i18n from 'i18next';
import { type ReactNode, useEffect, useState } from 'react';

import AppConfigContext, { type AppConfig } from './AppConfigContext';

export default function AppConfigProvider({
  children
}: {
  children: ReactNode;
}) {
  const [appConfig, setAppConfig] = useState<AppConfig | undefined>(undefined);

  useEffect(() => {
    loadAppConfig();
  }, []);

  const loadAppConfig = async () => {
    try {
      let appConfigModule: AppConfig;
      if (window.location.hostname.includes('aligna')) {
        appConfigModule = await import(
          '../assets/config/app-config-overseas.json'
        );
        setAppConfig(appConfigModule);
        document.title = appConfigModule.appName;
        i18n.changeLanguage(appConfigModule.defaultLanguage);
      } else if (window.location.hostname.includes('lanquan')) {
        appConfigModule = await import(
          '../assets/config/app-config-local.json'
        );
        setAppConfig(appConfigModule);
        document.title = appConfigModule.appName;
        i18n.changeLanguage(appConfigModule.defaultLanguage);
      } else {
        appConfigModule = await import(
          '../assets/config/app-config-local.json'
        );
        setAppConfig(appConfigModule);
        document.title = appConfigModule.appName;
        i18n.changeLanguage(appConfigModule.defaultLanguage);
      }
    } catch (error) {
      console.error('Failed to load app config', error);
    }
  };

  return (
    <AppConfigContext.Provider value={appConfig}>
      {children}
    </AppConfigContext.Provider>
  );
}
